@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400..900&display=swap');

body {
  font-family: Arial, sans-serif;
  background-color: #02002f;
  color: #ffffff;
}

.header {
  background-color: #030042;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
}

.logo {
  font-size: 24px;
  font-weight: lighter;
  font-family: "Orbitron", sans-serif;
  margin: 0;
}

.nav {
  display: flex;
  gap: 15px;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.3s;
}

.nav-link:hover {
  color: #c7c7c7;
}
