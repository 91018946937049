.card {
  display: flex;
  border: 1px solid rgb(53, 0, 53);
  border-radius: 8px;
  background-color: black;
  max-width: 800px;
  flex: 2 1;
  margin: 14px 0px;
}

.card-content {
  padding: 16px;
  flex: 1;
}

.card-image {
  width: 300px;
}

.card-image-wrapper {
  margin: 0 auto;
}

.card-title {
  font-size: 20px;
  margin: 0px 0px 10px;
}

.card-info {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 12px;
  font-family: monospace;
  padding-bottom: 7px;
  border-bottom: 1px solid rgb(56, 56, 56);
  span {
    margin-left: 4px;
  }
}

.card-icon {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #333;
}

.card-description {
  margin-bottom: 0;
}

.card-action {
  display: flex;
  align-items: center;
  margin-top: 10px;
  button {
    margin-right: 10px;
  }
}

.download-link {
  background-color: green;
  color: white;

  svg {
    margin-right: 10px;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  padding: 15px 30px;
  font-size: 20px;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}

@media (max-width: 694px) {
  .card {
    flex-direction: column-reverse;
  }
}
