#about {
  text-align: center;
}

p {
  max-width: 800px;
  margin: 12px auto;
  line-height: 1.5;
}

img {
  width: 80%;
  max-width: 800px;
}